import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { getVideo } from '../graphql/queries'
import { deleteVideo, updateVideoStatus } from '../graphql/mutation'
import ProgressBar from '../components/circularProgress/circularProgress'
import ReactPlayer from 'react-player'
import SaveIcon from '@mui/icons-material/Save'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import { Container, Box, Typography, Alert, Snackbar } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { updateFeedStatus } from '../graphql/mutation'
import InputLabel from '@mui/material/InputLabel'

export const statusArray = [
  { value: 'RUNNING', name: 'RUNNING' },
  { value: 'SUCCEEDED', name: 'SUCCEEDED' },
  { value: 'FAILED', name: 'FAILED' },
  { value: 'IN_QUE', name: 'IN_QUE' },
  { value: 'PENDING', name: 'PENDING' },
  { value: 'THROTTLED', name: 'THROTTLED' },
]


export const feedStatusArray = [
  { value: 'PENDING', name: 'PENDING' },
  { value: 'CONFIRMED', name: 'CONFIRMED' },
  { value: 'REJECTED', name: 'REJECTED' },
]

export default function SingleVideoPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [videoData, setVideoData] = useState(null)
  const [previewMode, setPreviewMode] = useState(false)
  const [notification, setNotification] = useState('')
  const [feedStatus, setFeedStatus] = useState(null)
  const [videoStatus, setVideoStatus] = useState()
  const { loading, error, data, refetch } = useQuery(getVideo, {
    variables: { videoId: id },
  })
  useEffect(() => {
    if (data?.getVideo) {
      setVideoData(data?.getVideo)
      setFeedStatus(data?.getVideo?.feed?.status)
      setVideoStatus(data?.getVideo?.status)
    }
  }, [data])
  const [updateFeedMutation] = useMutation(updateFeedStatus)
  const [deleteVideoMutation] = useMutation(deleteVideo)
  const [updateVideoStatusMutation] = useMutation(updateVideoStatus)
  if (loading) {
    return <ProgressBar />
  }

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>
  }
  const handleFeedStatus = (e) => {
    const { name, value } = e.target
    setFeedStatus(value)
  }
  const handleVideoStatus = (e) => {
    const { name, value } = e.target
    setVideoStatus(value)
  }

  const handleDelete = async () => {
    try {
      const response = await deleteVideoMutation({
        variables: {
          videoId: id,
        },
      })
      setNotification(response.data.deleteVideoAdmin)
      setTimeout(() => {
        navigate('/videos')
      }, 3000)
    } catch (err) {
      console.error('Error deleting video:', err)
      setNotification('Failed to delete the video.')
    }
  }


  const handlePreviewClick = (e) => {
    e.preventDefault(e)
    setPreviewMode(!previewMode)
  }

  const formatDateTimeToLocal = (isoDateString) => {
    const date = new Date(isoDateString)
    return date.toLocaleString('tr-TR')
  }

  const handleUpdateVideoStatus = async () => {
    const gpqResponse = await updateVideoStatusMutation({
      variables: {
        videoId: id,
        status: videoStatus,
      },
    })
    if (gpqResponse.data) {
      setNotification('Feed Status successfully updated.')
      setTimeout(() => {
        setNotification(null)
      }, 3000)
      const data = await refetch()
      setVideoData(data && data.data && data.data.getVideo)
    } else {
      setNotification('Error')
      setTimeout(() => {
        setNotification(null)
      }, 3000)
    }
  }


  const handleUpdateFeedStatus = async () => {
    const gpqResponse = await updateFeedMutation({
      variables: {
        feedId: videoData.feed.id,
        status: feedStatus,
      },
    })
    if (gpqResponse.data) {
      setNotification('Feed Status successfully updated.')
      setTimeout(() => {
        setNotification(null)
      }, 3000)
      const data = await refetch()
      setVideoData(data && data.data && data.data.getVideo)
    } else {
      setNotification('Error')
      setTimeout(() => {
        setNotification(null)
      }, 3000)
    }
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Box display="flex" sx={{ bgcolor: '#fff', padding: '16px 16px 16px 16px', borderRadius: 2, boxShadow: 3 }}>
        <Box style={{ flex: 1 }} mr={3}>
          <Box mb={2}>
            <Typography variant="body1" color="textSecondary">
              User ID:
              <Link to={`/users/${videoData && videoData.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="body1" color="primary" component="span">
                  {videoData && videoData.userId}
                </Typography>
              </Link>
            </Typography>
          </Box>
          <Box>
            <TextField fullWidth disabled label="Created At" variant="outlined"
                       value={videoData ? formatDateTimeToLocal(videoData.createdAt) : ''} sx={{ mb: 2 }} />
            <TextField fullWidth disabled label="Updated At" variant="outlined"
                       value={videoData ? formatDateTimeToLocal(videoData.updatedAt) : ''} sx={{ mb: 2 }} />
            <TextField fullWidth disabled label="ID" variant="outlined" value={videoData ? videoData.id : ''}
                       sx={{ mb: 2 }} />
            {
              videoData?.prompt &&
              <TextField fullWidth disabled label="Prompt" variant="outlined" value={videoData ? videoData.prompt : ''}
                         sx={{ mb: 2 }} />
            }

            <TextField fullWidth disabled label="Task ID" variant="outlined" value={videoData ? videoData.taskId : ''}
                       sx={{ mb: 2 }} />
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select onChange={handleVideoStatus} label="Status" value={videoData ? videoStatus : ''}>
                {
                  statusArray.map(({ name, value }) => (
                    <MenuItem key={value} value={value}>{name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TextField fullWidth disabled label="URL" variant="outlined" value={videoData ? videoData.url : ''}
                       sx={{ mb: 2 }} />
            <TextField
              fullWidth
              disabled
              label="isPublished"
              variant="outlined"
              value={videoData && videoData.feedStatus === 'CONFIRMED'}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-disabled': {
                    backgroundColor: '#e0f7fa', // Light blue background when disabled
                    color: '#0277bd', // Darker blue text for better contrast
                  },
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: '#0277bd', // Lighter blue for the label
                },
              }}
            />

            {
              videoData?.inputImageUrl &&
              <TextField fullWidth disabled label="Input Image URL" variant="outlined"
                         value={videoData.inputImageUrl} sx={{ mb: 2 }} />
            }

            {
              videoData?.errorReason &&
              <TextField fullWidth disabled label="Error Reason" variant="outlined"
                         value={videoData ? videoData.errorReason : ''} sx={{ mb: 2 }} />
            }
            <Button onClick={handleUpdateVideoStatus} variant="contained" color="primary" startIcon={<SaveIcon />}>
              Update Video Status
            </Button>
          </Box>
          <Snackbar open={!!notification} autoHideDuration={3000} style={{ width: '300px' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="info" sx={{ width: '100%' }}>
              <Box width="100%">
                {notification}
              </Box>
            </Alert>
          </Snackbar>
        </Box>

        <Box style={{ flex: 1 }}>
          <Box mb={2}>
            <Box display="flex" flexDirection="column">
              <Box width="100%" display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<RemoveRedEyeIcon />}
                  onClick={handlePreviewClick}
                >
                  {previewMode ? 'Hide Preview' : 'Preview Video'}
                </Button>
              </Box>

              {previewMode && videoData && videoData.url && (
                <Box mb={2}>
                  <ReactPlayer url={videoData.url} controls width="100%" height="auto" controls playing />
                </Box>
              )}

              {!previewMode && videoData && videoData.thumbnailUrl && (
                <Box mb={2}>
                  <img src={videoData.thumbnailUrl} alt="Video Thumbnail"
                       style={{ maxWidth: '100%' }} />
                </Box>
              )}
            </Box>
            {
              feedStatus && <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                 mb={2}>
                <Typography mb={1} fontSize={16}>Video Feed Status</Typography>
                <FormControl fullWidth>
                  <Select
                    name="feedStatus"
                    value={feedStatus}
                    onChange={handleFeedStatus}
                  >
                    {feedStatusArray.map(i => (
                      <MenuItem value={i.value}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Box>
            }

          </Box>
          <Box display="flex" justifyContent="space-between">
            {
              feedStatus &&
              <Button onClick={handleUpdateFeedStatus} variant="contained" color="primary" startIcon={<SaveIcon />}>
                Update Feed Status
              </Button>
            }
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
              Delete Video
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
