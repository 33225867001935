import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { getAllUsers } from '../../graphql/queries';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { createOneCredit } from '../../graphql/mutation';
import DatePicker from '../../components/datePicker';

export default function CreateCredit() {
  const { data } = useQuery(getAllUsers);
  const [show, setShow] = React.useState('none');
  const [selectedDate, setSelectedDate] = React.useState(null); // State for selected date
  const [credit, setCredit] = React.useState({
    userId: '',
    type: '',
    amount: '',
  });
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date state
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setCredit((prev) => ({ ...prev, [name]: value }));
  };

  const userData = data && data.getAllUsers ? data.getAllUsers : [];

  const [createOneCreditMutation] = useMutation(createOneCredit);

  const handleCreateCredit = async () => {
    const gpqResponse = await createOneCreditMutation({
      variables: {
        userId: credit.userId,
        amount: parseInt(credit.amount),
        date: selectedDate, // Pass selected date to mutation
      },
    });
    if (gpqResponse.data) {
      setShow('block');
      // Set "none" after 3 seconds
      setTimeout(() => {
        setShow('none');
      }, 3000);
    }
  };
  return (
    <div>
      <div style={{ height: '100vh' }}>
        <div style={{ display: show }}>
          <Alert severity="success" color="info">
            Credit successfully created.
          </Alert>
        </div>
        <div className="d-flex flex-column  p-5">
          <div className="mb-3">
            {/* Pass handleDateChange as onChange prop */}
            <DatePicker label="Basic date picker" value={selectedDate} onChange={handleDateChange} />
          </div>

          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-filled-label">User</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="userId"
              onChange={handleOnChange}
            >
              {userData &&
                userData.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.email}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}></FormControl>
          <TextField
            onChange={handleOnChange}
            name="amount"
            className="w-25"
            id="filled-basic"
            label="Amount"
            variant="filled"
          />
          <div className="w-25 mt-3">
            <Button onClick={handleCreateCredit} variant="contained">
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
