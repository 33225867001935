import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import {
  Toolbar,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { useQuery } from '@apollo/client'
import { getAdminFeed } from '../../graphql/queries'
import { Search } from '@mui/icons-material'
import { feedStatusArray } from '../../pages/singlevideoPage'

const columns = [
  { field: 'id', headerName: 'Feed ID', flex: 3 },
  { field: 'likeCount', headerName: 'Like Count', flex: 1 },
  { field: 'status', headerName: 'Feed Status', flex: 3 },
  { field: 'userName', headerName: 'User Name', flex: 3 },
  {
    field: 'thumbnailUrl',
    headerName: 'Thumbnail',
    flex: 3,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Thumbnail"
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          borderRadius: '4px',
        }}
      />
    ),
  },
]

export default function FeedTable() {
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [status, setStatus] = useState('')

  // Use the useQuery hook with proper variables
  const { loading, error, data, refetch } = useQuery(getAdminFeed, {
    variables: {
      status: status || null,
      rcUserId: searchInput || null,
    },
    fetchPolicy: 'network-only',
  })

  // Transform the data whenever it changes
  const rows = data?.getAdminFeed?.feed?.map((item) => ({
    id: item.id,
    videoId: item.video.id,
    likeCount: item.likeCount,
    status: item.status,
    thumbnailUrl: item.video.thumbnailUrl,
    userName: item.user.userName || 'N/A',
  })) || []

  const ROWS_PER_PAGE = 5
  const totalPages = Math.ceil(rows.length / ROWS_PER_PAGE)
  const paginatedRows = rows.slice(
    (currentPage - 1) * ROWS_PER_PAGE,
    currentPage * ROWS_PER_PAGE,
  )

  // Reset pagination when filters change
  useEffect(() => {
    setCurrentPage(1)
  }, [status, searchInput])

  const handleSearch = async () => {
    try {
      await refetch()
    } catch (err) {
      console.error('Error fetching data:', err)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value
    setStatus(newStatus)
    try {
      await refetch({
        status: newStatus || null,
        rcUserId: searchInput || null,
      })
    } catch (err) {
      console.error('Error fetching data:', err)
    }
  }

  const handlePagination = (_, page) => {
    setCurrentPage(page)
  }

  const handleRowClick = (params) => {
    navigate(`/videos/${params.row.videoId}`)
  }

  if (error) {
    return (
      <Box p={3} color="error.main">
        Error: {error.message}
      </Box>
    )
  }
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Toolbar sx={{ gap: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
          <TextField
            fullWidth
            id="filled-basic"
            name="rcUserId"
            label="RevenueCat User ID"
            variant="filled"
            value={searchInput}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearch} edge="end">
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Box>
        <FormControl variant="filled" sx={{ minWidth: 200 }}>
          <InputLabel>Status</InputLabel>
          <Select
            name="feedStatus"
            value={status}
            onChange={handleStatusChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {feedStatusArray.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>

      <Box sx={{ position: 'relative', minHeight: '400px' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={paginatedRows}
            columns={columns}
            rowHeight={250}
            autoHeight
            hideFooter
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-cell': {
                cursor: 'pointer',
              },
            }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePagination}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </Box>
    </Box>
  )
}