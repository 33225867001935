import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { getCredit } from '../../graphql/queries'
import { Modal, Alert, TextField, Box, IconButton, Grid, Typography, Button, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from '@apollo/client'
import { updateCredit } from '../../graphql/mutation'
import { update } from 'react-spring'

export default function EditCreditModal({ creditId, open, onClose }) {
  const [credit, setCredit] = useState()
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const [amount, setAmount] = useState('')
  const [expiry, setExpiry] = useState('')
  const { loading, error, data, refetch } = useQuery(getCredit, {
    variables: { creditId: creditId },
    onCompleted: data => setCredit(data && data.getCredit ? data.getCredit : {}),
  })
  const handleChange = (prop) => (event) => {
    setCredit({ ...credit, [prop]: event.target.value })
  }
  const [updateCreditMutation] = useMutation(updateCredit)
  const handleUpdate = async () => {
    try {
      await updateCreditMutation({
        variables: {
          creditId: creditId,
          expiryDate: new Date(credit?.expiryDate),
          amount: Number(credit?.amount),
        },
      })
      setMessage('Credit updated successfully')
      setSeverity('success')
      refetch()
    } catch (error) {
      setMessage(`Credit failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setAlertOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertOpen(false)
  }
  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose} style={{ width: '300px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <Box width="100%">
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '15px',
          }}
        >
          <IconButton
            onClick={onClose}
            color="secondary"
            aria-label="close modal"
            component="span"
            sx={{ alignSelf: 'flex-end' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h4" align="center" gutterBottom>
            Credit Edit Form
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="id" label="ID" type="text"
                         value={credit?.id || ''}
                         onChange={handleChange('id')} />
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="createdAt" label="Created At"
                         type="text"
                         value={credit?.createdAt ? new Date(credit.createdAt).toLocaleString() : ''}
                         onChange={handleChange('createdAt')} />
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="updatedAt" label="Updated At"
                         type="text"
                         value={credit?.updatedAt ? new Date(credit.updatedAt).toLocaleString() : ''}
                         onChange={handleChange('updatedAt')} />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="transactionId" label="Transaction ID"
                         type="text"
                         value={credit?.transactionId || ''}
                         onChange={handleChange('transactionId')} />
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="date" label="Date" type="text"
                         value={credit?.date ? new Date(credit.date).toLocaleString() : ''}
                         onChange={handleChange('date')} />
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="rcEventId" label="RC Event ID"
                         type="text"
                         value={credit?.rcEventId || ''}
                         onChange={handleChange('rcEventId')} />


            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={6} md={6}>
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="productName"
                         label="Product Name" type="text"
                         value={credit?.productName || ''}
                         onChange={handleChange('productName')} />
              <TextField style={{ marginBottom: '20px' }} disabled mb={1} fullWidth id="productType"
                         label="Product Type" type="text"
                         value={credit?.productType || ''}
                         onChange={handleChange('productType')} />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField style={{ marginBottom: '20px' }} fullWidth id="amount" label="Amount" type="number"
                         value={credit?.amount || ''}
                         onChange={handleChange('amount')} />

              <TextField style={{ marginBottom: '20px' }} fullWidth id="expiryDate" label="Expiry Date" type="date"
                         value={credit?.expiryDate ? new Date(credit.expiryDate).toISOString().split('T')[0] : ''}
                         onChange={handleChange('expiryDate')} />
            </Grid>
          </Grid>

          <Button onClick={handleUpdate} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  )
};