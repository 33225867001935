import React, { useState } from 'react'
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation } from '@apollo/client'
import { userTransfer } from '../../graphql/mutation'

export default function UserTransferComponent() {
  const [sourceID, setSourceID] = useState('')
  const [destID, setDestID] = useState('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const [userTransferMutation] = useMutation(userTransfer)

  const handleSendFcm = async () => {
    if (!sourceID || !destID) {
      throw new Error('Source ID, destination ID cannot be empty')
    }

    try {
      const gpqResponse = await userTransferMutation({
        variables: {
          destinationId: destID,
          sourceId: sourceID,
        },
      })
      setMessage('Push notification sent successfully')
      setSeverity('success')
    } catch (error) {
      setMessage(`Push notification failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setOpen(true)
    }
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} style={{ width: '300px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <Box width="100%">
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" id="tableTitle" component="div">
                  User Transfer
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <Box display="flex" flexDirection="column" flexWrap="wrap" p={1}>
            <Box display="flex" justifyContent="space-between" width="100%" gap={5} p={1}>
              <TextField
                fullWidth
                label="Source ID"
                value={sourceID}
                onChange={(event) => setSourceID(event.target.value)}
              />
              <TextField
                fullWidth
                label="Destination ID"
                value={destID}
                onChange={(event) => setDestID(event.target.value)}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%" p={1}>
              <Button variant="contained" color="primary" onClick={handleSendFcm}>
                Transfer
              </Button>
            </Box>
          </Box>
        </Table>
      </TableContainer>
    </>
  )
}
