import TextField from '@mui/material/TextField'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { getAllStyles, getCategory } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'
import ReactPlayer from 'react-player'
import './video.css'
import { updateExplore, deleteExplore, updateCategory, deleteCategory } from '../graphql/mutation'
import Alert from '@mui/material/Alert'
import { uploadToS3 } from '../utils/uploadToS3'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'


const categoryType = [
  { value: 'TRANSITION', name: 'TRANSITION' },
  { value: 'MORPHING', name: 'MORPHING' },
  { value: 'HUGGING', name: 'HUGGING' },
  { value: 'IMAGE2VIDEO', name: 'IMAGE2VIDEO' },
]
const isExplore = [
  { value: false, name: 'FALSE' },
  { value: true, name: 'TRUE' },
]


export default function SingleCategoryPage() {
  const { id } = useParams()
  const [categoryData, setCategoryData] = useState(null)
  const [message, setMessage] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [selectedThumbnail, setSelectedThumbnail] = useState(null)
  const [video, setVideo] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [previewMode, setPreviewMode] = useState(false)
  const [category, setCategory] = useState({
    prompt: '',
    url: '',
    thumbnailUrl: '',
    motionScore: '',
    style: '',
    aspectRatio: '',
    gif: '',
    type: '',
    isExplore: null,
  })

  const { loading, error, data } = useQuery(getCategory, {
    variables: { categoryId: id },
    onCompleted: (data) => {
      setCategoryData(data.getCategory)
    },
  })


  useEffect(() => {
    if (categoryData) {
      setCategory({
        prompt: categoryData.prompt,
        url: categoryData.video,
        thumbnailUrl: categoryData.thumbnail,
        name: categoryData.name,
        description: categoryData.description,
        model: categoryData.model,
        gif: categoryData.gif,
        type: categoryData.type,
        isExplore: categoryData.isExplore,
      })
      setSelectedGifUrl(categoryData.gif)
      setSelectedVideo(categoryData.video)
      setSelectedThumbnail(categoryData.thumbnail)
    }
  }, [categoryData])

  const [updateCategoryMutation] = useMutation(updateCategory)
  const [deleteExploreMutation] = useMutation(deleteCategory)

  if (loading) {
    return <ProgressBar />
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const handlePreviewClick = () => {
    setPreviewMode(!previewMode) // Toggle preview mode
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setCategory((prev) => ({ ...prev, [name]: value }))
  }
  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))

    setGif(selectedFile)
  }
  const handleVideoSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedVideo(URL.createObjectURL(selectedFile))
    setVideo(selectedFile)
  }
  const handleThumbnailSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedThumbnail(URL.createObjectURL(selectedFile))
    setThumbnail(selectedFile)
  }
  const handleUpdateExplore = async () => {
    const lastGifUrl = gif ? await uploadToS3(gif, 'gif') : categoryData.gif
    const lastVideoUrl = video ? await uploadToS3(video, 'video') : categoryData.video
    const thumbnailUrl = thumbnail ? await uploadToS3(thumbnail, 'img') : categoryData.thumbnail
    const gpqResponse = await updateCategoryMutation({
      variables: {
        categoryId: id,
        prompt: category.prompt,
        video: lastVideoUrl,
        thumbnailUrl: thumbnailUrl,
        name: category.name,
        description: category.description,
        model: category.model,
        gif: lastGifUrl,
        categoryType: category.type,
        isExplore: category.isExplore,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  const handleDeleteExplore = async () => {
    const gpqResponse = await deleteExploreMutation({
      variables: {
        categoryId: id,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully deleted..')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  return (
    <>
      {
        message &&
        <div className="fixed-top">
          <Alert severity="success" color="info">
            {message}
          </Alert>
        </div>
      }
      <div className="container mt-4">

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <TextField disabled fullWidth id="filled-disabled" label="Created at" variant="filled"
                           value={categoryData ? categoryData.createdAt : ''} />
                <TextField disabled fullWidth id="filled-disabled" label="ID" variant="filled"
                           value={categoryData ? categoryData.id : ''} />
                <TextField onChange={handleChange} name="prompt" fullWidth id="filled-disabled" label="Prompt"
                           variant="filled"
                           value={category.prompt} />
                <TextField onChange={handleChange} name="name" fullWidth id="filled-disabled" label="Name"
                           variant="filled"
                           value={category.name} />
                <TextField onChange={handleChange} name="description" fullWidth id="filled-disabled" label="Description"
                           variant="filled"
                           value={category.description} />
                <TextField onChange={handleChange} name="model" fullWidth id="filled-disabled" label="Model"
                           variant="filled"
                           value={category.model} />
                <TextField onChange={handleChange} name="thumbnailUrl" fullWidth id="filled-disabled"
                           label="Thumbnail Url" variant="filled"
                           value={category.thumbnailUrl} />
                <TextField onChange={handleChange} fullWidth id="filled-disabled" label="Video Url" variant="filled"
                           value={category.url} />
                <div className="mb-3">
                  <span>isExplore</span>
                  <FormControl fullWidth>
                    <Select
                      name="isExplore"
                      onChange={handleChange}
                      value={category.isExplore}
                    >
                      {isExplore.map(i => (
                        <MenuItem value={i.value}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </div>

                <div className="mb-3">
                  <span>Category</span>
                  <FormControl fullWidth>
                    <Select
                      name="type"
                      onChange={handleChange}
                      value={category.type}
                    >
                      {categoryType.map(i => (
                        <MenuItem value={i.value}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </div>
                <div className="w-25 mb-3">
                </div>

                <div>
                  {
                    category && category.thumbnailUrl && (
                      <div>
                        <img
                          className="img-thumbnail"
                          src={category ? selectedThumbnail : ''}
                          alt="Video Thumbnail"
                        />
                        <input
                          name="thumbnail"
                          type="file"
                          onChange={handleThumbnailSelected}
                        />
                        <div />
                      </div>
                    )
                  }


                </div>

                {category && category.url && (
                  <div className="d-flex justify-content-center mt-3">
                    <div className="embed-responsive embed-responsive-16by9">
                      <ReactPlayer className="embed-responsive-item" url={category ? selectedVideo : ''} controls
                                   playing />
                    </div>
                    <input
                      name="gif"
                      type="file"
                      onChange={handleVideoSelected}

                    />
                  </div>
                )}
              </div>
              <div className=" d-block">
                <span className="fs-3">Gif</span>
                {
                  category && category.gif && (
                    <div>

                      <img className="img-thumbnail w-50" src={category ? selectedGifUrl : ''}
                           alt="Video Thumbnail" />
                      <input
                        name="gif"
                        type="file"
                        onChange={handleGifSelected}

                      />
                    </div>
                  )
                }

              </div>
              <div className="card-footer d-flex justify-content-between bg-light">
                <button onClick={handleUpdateExplore} className="btn btn-primary"><SaveIcon /> Save</button>
                <button onClick={handleDeleteExplore} className="btn btn-danger"><DeleteIcon /> Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
