import React from 'react'
import { Card, Grid, Typography } from '@mui/material'

function UserInfo({ userData }) {

  const copy2Clipboard = () => {
    navigator.clipboard.writeText(userData?.fcmId ? userData?.fcmId : '')

  }
  return (
    <Card>
      <Grid container sx={{ margin: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">ID:</Typography>
            <Typography ml={1} variant="body1">{userData.id || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Total Credit</Typography>
            <Typography ml={1}
                        variant="body1">{userData.totalCredit ? userData.totalCredit.total : 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Created At:</Typography>
            <Typography ml={1} variant="body1">{userData.createdAt || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Username:</Typography>
            <Typography ml={1} variant="body1">{userData.userName || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Premium Expiry Date:</Typography>
            <Typography ml={1} variant="body1">{userData.premiumExpiryDate || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">FCM ID:</Typography>
            <Typography
              onClick={copy2Clipboard}
              ml={1}
              variant="body1">{userData.fcmId ? (userData.fcmId.length > 20 ? userData.fcmId.substring(0, 20) + '...' : userData.fcmId) : 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">RC User ID:</Typography>
            <Typography ml={1} variant="body1">{userData.rcUserId || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Store:</Typography>
            <Typography ml={1} variant="body1">{userData.store || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">First Seen Location:</Typography>
            <Typography ml={1} variant="body1">{userData.firstSeenLocation || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={3} display="flex" alignItems="center" spacing={2}>
            <Typography fontWeight={500} variant="h8">Last Seen Location:</Typography>
            <Typography ml={1} variant="body1">{userData.lastSeenLocation || 'N/A'}</Typography>
          </Grid>
        </Grid>
      </Grid>

    </Card>
  )
}

export default UserInfo