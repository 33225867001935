import "./chart.css";
import { BarChart, YAxis, Bar, Tooltip, XAxis, ResponsiveContainer } from "recharts";
import { Card, CardActions, CardContent, CardHeader, Divider } from '@mui/material'
import Button from '@mui/material/Button'
import * as PropTypes from 'prop-types'
import { ArrowRightIcon } from '@mui/x-date-pickers'

function ArrowClockwiseIcon(props) {
  return null
}

ArrowClockwiseIcon.propTypes = { fontSize: PropTypes.string }
const Chart = ({ aspect = 1, title, predictionData , sx}) => {

    if (!predictionData) {
        return <div>Loading...</div>;
    }
    return (
      <Card sx={sx}>
        <CardHeader
          title="Generates"
        />
        <CardContent>
          <ResponsiveContainer width="100%" aspect={aspect}>
            <BarChart width={300} height={200} data={predictionData.reverse()} margin={{top: 10, bottom: 10, left: 10}} >
              <XAxis dataKey="date" />
              <YAxis dataKey="count" />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>

        </CardActions>
      </Card>


    );
};

export default Chart;
