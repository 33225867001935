import React, { useState } from 'react'
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useMutation } from '@apollo/client'
import { createCategory } from '../../graphql/mutation'
import { sendFcm } from '../../graphql/mutation'

function SendPush({ userFcmId }) {
  const [fcmId, setFcmId] = useState('')
  const [text, setText] = useState('')
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const [sendFcmMutation] = useMutation(sendFcm)

  const handleSendFcm = async () => {
    if (!fcmId || !title || !text) {
      throw new Error('FCM ID, title or text cannot be empty')
    }

    try {
      await sendFcmMutation({
        variables: {
          fcmId: fcmId,
          title: title,
          message: text,
        },
      })

      setMessage('Push notification sent successfully')
      setSeverity('success')
    } catch (error) {
      setMessage(`Push notification failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setOpen(true)
    }
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', wordWrap: 'break-word' }}>
          <Box width="100%" sx={{ overflowWrap: 'break-word' }}>
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" id="tableTitle" component="div">
                  Send Push Notification
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <Box display="flex" flexDirection="column" flexWrap="wrap" p={1}>
            <Box display="flex" justifyContent="space-between" width="100%" gap={5} p={1}>
              <TextField
                fullWidth
                label="FCM ID"
                value={userFcmId ? userFcmId : fcmId}
                onChange={(event) => setFcmId(event.target.value)}
              />
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)} // added TextField for Title input
              />
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%" gap={5} p={1}>
              <TextField
                fullWidth
                label="Text"
                value={text}
                onChange={(event) => setText(event.target.value)}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%" p={1}>
              <Button variant="contained" color="primary" onClick={handleSendFcm}>
                Send Push Notification
              </Button>
            </Box>
          </Box>
        </Table>
      </TableContainer>
    </>
  )
}

export default SendPush