import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { getSystem } from '../graphql/queries'
import { updateSystem } from '../graphql/mutation'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'


const SystemPage = () => {
  const [message, setMessage] = useState(null)
  const [updateSystem1, setUpdateSystem1] = useState({
    id: '',
    system: '',
    provider: '',
    type: '',
    createdAt: '',
    updatedAt: '',
  })
  const [updateSystem2, setUpdateSystem2] = useState({
    id: '',
    system: '',
    provider: '',
    type: '',
    createdAt: '',
    updatedAt: '',
  })
  const [updateSystemMutation] = useMutation(updateSystem)

  const { loading: loading1, error: error1, data: data1 } = useQuery(getSystem, {
    variables: { systemType: 'img2Video' },
  })

  const { loading: loading2, error: error2, data: data2 } = useQuery(getSystem, {
    variables: { systemType: 'text2Video' },
  })

  const fetchedImg2VideoData = data1?.getSystem || {}
  const fetchedText2VideoData = data2?.getSystem || {}

  useEffect(() => {
    if (data1) {
      setUpdateSystem1({
        id: fetchedImg2VideoData.id,
        system: fetchedImg2VideoData.system,
        provider: fetchedImg2VideoData.provider,
        type: fetchedImg2VideoData.type,
        createdAt: fetchedImg2VideoData.createdAt,
        updatedAt: fetchedImg2VideoData.updatedAt,
      })
    }

    if (data2) {
      setUpdateSystem2({
        id: fetchedText2VideoData.id,
        system: fetchedText2VideoData.system,
        provider: fetchedText2VideoData.provider,
        type: fetchedText2VideoData.type,
        createdAt: fetchedText2VideoData.createdAt,
        updatedAt: fetchedText2VideoData.updatedAt,
      })
    }
  }, [data1, data2])

  const handleChange1 = (e) => {
    console.log(e)
    const { name, value } = e.target
    setUpdateSystem1((prev) => ({ ...prev, [name]: value }))
  }
  const handleChange2 = (e) => {
    console.log(e)
    const { name, value } = e.target
    setUpdateSystem2((prev) => ({ ...prev, [name]: value }))
  }

  const handleUpdate1 = async () => {
    const gpqResponse = await updateSystemMutation({
      variables: {
        updateSystemId: updateSystem1.id,
        system: updateSystem1.system,
        provider: updateSystem1.provider,
        systemType: updateSystem1.type,
      },
    })
    if (gpqResponse.data) {
      setMessage('img2Video system has been successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  const handleUpdate2 = async () => {
    const gpqResponse = await updateSystemMutation({
      variables: {
        updateSystemId: updateSystem2.id,
        system: updateSystem2.system,
        provider: updateSystem2.provider,
        systemType: updateSystem2.type,
      },
    })
    if (gpqResponse.data) {
      setMessage('text2Video system has been successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  return updateSystem1 && (

    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        p: 3,
        gap: 3,
      }}>
        {
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            mt: 2,
            width: '100%',
            zIndex: 345345,
          }}>
            {message &&
              <Alert severity="success" color="success">
                {message}
              </Alert>
            }
          </Box>
        }
        <div>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>img2Video sistemi şu
            anda {updateSystem1.provider} üzerinden {updateSystem1.system} şekilde
            çalışmaktadır.</Typography>
          <Paper elevation={3} sx={{ p: 3 }}>
            <TextField
              name="type"
              sx={{ mb: 3 }}
              label="Type"
              variant="outlined"
              fullWidth
              value={updateSystem1?.type || ''}
              disabled
            />
            <Select
              name="system"
              sx={{ mb: 3 }}
              label="System"
              variant="outlined"
              fullWidth
              value={updateSystem1?.system || ''}
              onChange={handleChange1}
              disabled
            >
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="UNLIMITED">UNLIMITED</MenuItem>
              <MenuItem value="BOTH">BOTH</MenuItem>
              Add more MenuItems as necessary
            </Select>
            <Select
              name="provider"
              sx={{ mb: 3 }}
              label="System"
              variant="outlined"
              fullWidth
              value={updateSystem1?.provider || ''}
              onChange={handleChange1}
            >
              <MenuItem value="RUNWAY">RUNWAY</MenuItem>
              <MenuItem value="LUMA">LUMA</MenuItem>
            </Select>
            <TextField
              sx={{ mb: 3 }}
              label="Created At"
              variant="outlined"
              fullWidth
              value={updateSystem1?.createdAt || ''}
              disabled
            />
            <TextField
              sx={{ mb: 3 }}
              label="Updated At"
              variant="outlined"
              fullWidth
              value={updateSystem1?.updatedAt || ''}
              disabled
            />
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleUpdate1}>Update System</Button>
          </Paper>
        </div>
        <div>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>text2Video sistemi şu
            anda {updateSystem2.provider} üzerinden {updateSystem2.system} şekilde
            çalışmaktadır.</Typography>
          <Paper elevation={3} sx={{ p: 3 }}>
            <TextField
              name="type"
              sx={{ mb: 3 }}
              label="Type"
              variant="outlined"
              fullWidth
              value={updateSystem2?.type || ''}
              disabled
            />
            <Select
              name="system"
              sx={{ mb: 3 }}
              label="System"
              variant="outlined"
              fullWidth
              value={updateSystem2?.system || ''}
              onChange={handleChange2}
            >
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="UNLIMITED">UNLIMITED</MenuItem>
              <MenuItem value="BOTH">BOTH</MenuItem>
              Add more MenuItems as necessary
            </Select>
            <Select
              name="provider"
              sx={{ mb: 3 }}
              label="System"
              variant="outlined"
              fullWidth
              value={updateSystem2?.provider || ''}
              onChange={handleChange2}
            >
              <MenuItem value="RUNWAY">RUNWAY</MenuItem>
              <MenuItem value="LUMA">LUMA</MenuItem>
            </Select>
            <TextField
              sx={{ mb: 3 }}
              label="Created At"
              variant="outlined"
              fullWidth
              value={updateSystem2?.createdAt || ''}
              disabled
            />
            <TextField
              sx={{ mb: 3 }}
              label="Updated At"
              variant="outlined"
              fullWidth
              value={updateSystem2?.updatedAt || ''}
              disabled
            />
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleUpdate2}>Update System</Button>
          </Paper>
        </div>
      </Box>
      <Box sx={{
        margin: 'auto',
        px: 3,
      }}>
        <Alert severity="success" color="info">
          text2Video BOTH system değeri arka tarafta kullandığımız userin video sayisina göre unlimited veya paid hesap
          kullanacagini belirler.
        </Alert>
        <Alert sx={{ mt: 1 }} severity="success" color="warning">
          img2Video her zaman paid hesapta calisacagi icin degistirilemez.
        </Alert>
      </Box>
    </>
  )
}

export default SystemPage