import { DataGrid } from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useQuery } from '@apollo/client'
import { getAllUsers, getAllvideos } from '../../graphql/queries'
import { useState, useEffect } from 'react'
import ProgressBar from '../circularProgress/circularProgress'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import LinearProgress from '@mui/material/LinearProgress'

const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt)
      return date.toLocaleDateString('tr-TR')
    },
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 2,
    minWidth: 250,
  },
  {
    field: 'model',
    headerName: 'Model',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'prompt',
    headerName: 'Prompt',
    flex: 3,
    minWidth: 300,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'progressRatio',
    headerName: 'Progress',
    flex: 1,
    minWidth: 300,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <LinearProgress
            variant="determinate"
            value={params.value}
            style={{ height: '8px', flexGrow: 1, marginRight: '10px' }}
          />
          <span>{`${params.value}%`}</span>
        </div>
      )
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 2,
    minWidth: 150,
    valueGetter: (params) => {
      const input_img = params.row.inputImageUrl
      return input_img ? 'img2Video' : 'text2Video'
    },
  },
]

export default function VideoTable() {
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState(null)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [status, setStatus] = useState()

  const { loading, error, data, refetch } = useQuery(getAllvideos, {
    variables: {
      page: currentPage,
      pageSize: 8,
      rcUserId: searchInput,
    },
    onCompleted: data => setTotalPage(data && data.getAllVideos && Math.round(data.getAllVideos.totalCount / 8))
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  if (loading) {
    return <ProgressBar />
  }

  const handleRowClick = (params) => {
    navigate(`/videos/${params.id}`)
  }

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handlePagination = (e, v) => {
    setCurrentPage(v)
    console.log(v)
  }

  const rowsData = data && data.getAllVideos && data.getAllVideos.videos ? data.getAllVideos.videos : []
  const filteredData = status ? rowsData.filter(item => item.status === status) : rowsData

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <div style={{ width: '100%' }}>
      <Toolbar>
        <TextField
          id="filled-basic"
          name="rcUserId"
          label="RevenueCat User ID"
          variant="filled"
          value={searchInput}
          onChange={handleSearchChange}
        />
      </Toolbar>
      <DataGrid
        rowHeight={100}
        rows={filteredData}
        columns={columns}
        pageSize={5}
        onRowClick={handleRowClick}
      />
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            page={currentPage}
            onChange={(e, value) => handlePagination(e, value)}
            count={totalPage}
            variant="outlined"
          />
        </Stack>
      </div>
    </div>
  )
}
