import React, { useEffect } from 'react'
import {
  TextField, Box, Card, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, Button,
  Paper, CardContent,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import TableComponent from '../components/supportComponent/table'
import { supportAdmin } from '../graphql/queries'
import { useQuery } from '@apollo/client'
import UserTransferComponent from '../components/supportComponent/transfer'
import UserInfo from '../components/supportComponent/userInfo'
import SendPush from '../components/supportComponent/push'
import VideoAnalytic from '../components/supportComponent/videoAnalytic'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

const Support = () => {
  const [userId, setUserId] = React.useState('')
  const [creditData, setCreditData] = React.useState([])
  const [userData, setUserData] = React.useState(null)
  const [videoData, setVideoData] = React.useState([])
  const { error, loading, data, refetch } = useQuery(supportAdmin, {
    variables: {
      rcUserId: userId,
    },
    skip: !userId, // skip when no userId
    onCompleted: data => {
      setCreditData(data && data.supportAdmin ? data.supportAdmin.credits : [])
      setUserData(data && data.supportAdmin ? data.supportAdmin.user : null)
      setVideoData(data && data.supportAdmin ? data.supportAdmin.videoAnalyticData : {})
    },
    onError: error => {
      setCreditData([])
      setUserData(null)
      setVideoData(null)
    },
  })

  return (
    <div style={{ padding: '8px' }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          fullWidth
          type="text"
          placeholder="Search by Revenuecat User Id..."
          value={userId}
          onChange={e => setUserId(e.target.value)}
          sx={{ flex: '4', backgroundColor: 'white' }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.2em',
              borderRadius: 4,
              padding: '4',
            },
          }}
        />
        {
          error && (
            <Box style={{ background: '#FF7F7F' }} width="100%" display="flex" justifyContent="flex-start"
                 border="1px solid #FF7F7F" borderRadius={4} p={2} mt={2} alignItems="center">
              <PriorityHighIcon />
              <Typography fontWeight={700} ml={2}>{error.message}</Typography>
            </Box>

          )
        }
      </Box>
      <Card style={{
        flex: '0 1 calc(50% - 10px)',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>

        {
          userData && userData ? (<UserInfo userData={userData} />) : null
        }

      </Card>
      <Box
        className="card-container"
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}
      >
        {
          userData && userData ? (<TableComponent title={'User Credits'} data={creditData} />) : null
        }

        <Card style={{
          flex: userData ? '0 1 calc(50% - 10px)' : '1',
          margin: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          {
            userData && userData ? (<VideoAnalytic videoData={videoData} />) : null
          }
          <UserTransferComponent title="User Info" />
          <SendPush title={'Send Push'} userFcmId={userData && userData.fcmId} />

        </Card>
      </Box>
    </div>
  )
}

export default Support