import React from 'react'
import { useSpring, animated } from 'react-spring'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink } from 'react-router-dom'

const MenuItem = ({ to, children }) => {
  const [hovered, setHovered] = React.useState(false)

  const hoverAnimation = useSpring({
    scale: hovered ? 1.1 : 1,
    config: { mass: 1, tension: 120, friction: 14 },
  })


  return (
    <animated.div
      style={{
        transform: hoverAnimation.scale.interpolate(scale => `scale(${scale})`),
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <NavLink style={{ textDecoration: 'none', color: 'black' }} to={to}>
        <div className="d-flex align-items-center">
          <div className="m-2">
            <MenuIcon />
          </div>
          {children}
        </div>
      </NavLink>
    </animated.div>
  )
}

const SideMenu = () => {
  return (
    <div>
      <MenuItem to="/users">Users</MenuItem>
      <MenuItem to="/videos">Videos</MenuItem>
      <MenuItem to="/styles">Explore</MenuItem>
      <MenuItem to="/categories">Categories</MenuItem>
      <MenuItem to="/feed">Feed</MenuItem>
      <MenuItem to="/support">Support</MenuItem>
      <MenuItem to="/accounts">Accounts</MenuItem>
    </div>
  )
}

export default SideMenu
