import { gql } from '@apollo/client'

export const createOneCredit = gql`
    mutation CreateOneCredit($date: String!, $userId: String!, $amount: Float!) {
        createOneCredit(date: $date, userId: $userId, amount: $amount) {
            amount
            id
        }
    }
`

export const updateUser = gql`
    mutation UpdateUser($userId: String!, $email: String, $role: Role, $isPremium: Boolean) {
        updateUser(userId: $userId, email: $email, role: $role, isPremium: $isPremium) {
            id
        }
    }
`

export const Login = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
            }
            token
        }
    }
`
export const createExplore = gql`
    mutation CreateExplore($webmUrl: String, $gif: String, $url: String, $thumbnailUrl: String, $aspectRatio: AspectRatioEnum, $style: ArtStyle, $motionScore: MotionScoreEnum, $categoryId: String, $prompt: String) {
        createExplore(webmUrl: $webmUrl, gif: $gif, url: $url, thumbnailUrl: $thumbnailUrl, aspectRatio: $aspectRatio, style: $style, motionScore: $motionScore, categoryId: $categoryId, prompt: $prompt) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            createdAt
            updatedAt
            aspectRatio
            location
            gif
            webmUrl
            categoryId
        }
    }
`
export const updateExplore = gql`
    mutation UpdateExplore($webmUrl: String, $gif: String, $url: String, $thumbnailUrl: String, $aspectRatio: AspectRatioEnum, $style: ArtStyle, $motionScore: MotionScoreEnum, $prompt: String, $categoryId: String, $exploreId: String) {
        updateExplore(webmUrl: $webmUrl, gif: $gif, url: $url, thumbnailUrl: $thumbnailUrl, aspectRatio: $aspectRatio, style: $style, motionScore: $motionScore, prompt: $prompt, categoryId: $categoryId, exploreId: $exploreId) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            createdAt
            updatedAt
            aspectRatio
            location
            gif
            webmUrl
            categoryId
        }
    }
`
export const deleteExplore = gql`
    mutation DeleteExplore($exploreId: String!) {
        deleteExplore(exploreId: $exploreId)
    }
`
export const createCategory = gql`
    mutation CreateCategory($model: ModelEnum, $prompt: [String!], $gif: String, $description: String, $thumbnailUrl: String, $video: String, $categoryType: CategoryType, $isExplore: Boolean, $name: String) {
        createCategory(model: $model, prompt: $prompt, gif: $gif, description: $description, thumbnailUrl: $thumbnailUrl, video: $video, categoryType: $categoryType, isExplore: $isExplore, name: $name) {
            createdAt
            description
            gif
            id
            isExplore
            model
            name
            prompt
            thumbnail
            type
            updatedAt
            video
        }
    }
`

export const updateCategory = gql`
    mutation UpdateCategory($categoryId: String, $isExplore: Boolean, $categoryType: CategoryType, $name: String, $video: String, $thumbnailUrl: String, $description: String, $gif: String, $prompt: [String!], $model: ModelEnum) {
        updateCategory(categoryId: $categoryId, isExplore: $isExplore, categoryType: $categoryType, name: $name, video: $video, thumbnailUrl: $thumbnailUrl, description: $description, gif: $gif, prompt: $prompt, model: $model) {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            isExplore
            model
            type
            createdAt
            updatedAt
        }
    }
`

export const deleteCategory = gql`
    mutation DeleteCategory($categoryId: String!) {
        deleteCategory(categoryId: $categoryId)
    }
`
export const deleteVideo = gql`
    mutation DeleteVideoAdmin($videoId: String!) {
        deleteVideoAdmin(videoId: $videoId)
    }
`

export const updateSystem = gql`
    mutation UpdateSystem($systemType: SystemTypeEnum!, $provider: SystemProviderEnum!, $system: RunwaySystemEnum!, $updateSystemId: String!) {
        updateSystem(systemType: $systemType, provider: $provider, system: $system, id: $updateSystemId) {
            id
            system
            provider
            type
            createdAt
            updatedAt
        }
    }
`
export const updateFeedStatus = gql`
    mutation UpdateFeedStatus($status: FeedStatus!, $feedId: String!) {
        updateFeedStatus(status: $status, feedId: $feedId) {
            id
        }
    }
`
export const sendFcm = gql`
    mutation SendFcm($message: String!, $title: String!, $fcmId: String!) {
        sendFcm(message: $message, title: $title, fcmId: $fcmId)
    }
`

export const userTransfer = gql`
    mutation UserTransfer($destinationId: String!, $sourceId: String!) {
        userTransfer(destinationId: $destinationId, sourceId: $sourceId)
    }

`

export const updateCredit = gql`
    mutation UpdateCredit($creditId: String!, $expiryDate: DateTime, $amount: Float) {
        updateCredit(creditId: $creditId, expiryDate: $expiryDate, amount: $amount) {
            id
        }
    }

`
export const updateVideoStatus = gql`
    mutation UpdateVideoStatus($status: StatusEnum!, $videoId: String!) {
        updateVideoStatus(status: $status, videoId: $videoId) {
            id
        }
    }
`

export const createRunwayAccount = gql`
    mutation CreateRunwayKey($accountName: String!, $teamId: Float!, $token: String!) {
        createRunwayAccount(accountName: $accountName, teamId: $teamId, token: $token) {
            id
        }
    }
`

export const updateRunwayAccount = gql`
    mutation UpdateRunwayAccount($keyId: String!, $token: String!) {
        updateRunwayAccount(keyId: $keyId, token: $token) {
            id
            accountName
            accountNo
            token
            teamId
            currentUsage
            isUnlimited
            createdAt
            updatedAt
            health {
                isActive
                error
            }
        }
    }
`
export const deleteRunwayAccount = gql`
    mutation DeleteRunwayAccount($accountId: String!) {
        deleteRunwayAccount(accountId: $accountId)
    }
`

