import React from 'react'
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TableBody,
  IconButton,
  TablePagination,
} from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import EditCreditModal from '../credit/editCredit'

function TableComponent({ title, data }) {
  const excludedKeys = ['__typename', 'productName', 'createdAt', 'updatedAt', 'rcEventId', 'isPromo', 'date']
  const keys = data.length > 0
    ? Object.keys(data[0]).filter(key => !excludedKeys.includes(key))
    : []

  const [openModal, setOpenModal] = React.useState(false)
  const [selectedRowId, setSelectedRowId] = React.useState(null)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(6)

  const handleOpenModal = (row) => {
    setSelectedRowId(row.id)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedRowId(null)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Card style={{ flex: '0 1 calc(50% - 10px)', margin: '5px' }}>
      <TableContainer style={{ flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={keys.length + 2}>
                <Typography variant="h6" id="tableTitle" component="div">
                  {title}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {keys.map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
              <TableRow key={i}>
                {keys.map((key) => (
                  <TableCell style={{ height: '83px' }} key={key}>
                    {(key === 'expiryDate' || key === 'date' || key === 'createdAt' || key === 'updatedAt') ? new Date(row[key]).toLocaleDateString() : row[key]}
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={() => handleOpenModal(row)}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <EditCreditModal
        open={openModal}
        onClose={handleCloseModal}
        creditId={selectedRowId} // Pass the selectedRowId instead of the entire row
      />
    </Card>
  )
}

export default TableComponent