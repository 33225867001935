import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { getRunwayAccount } from '../../graphql/queries'
import { Modal, Alert, TextField, Box, IconButton, Grid, Typography, Button, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from '@apollo/client'
import { updateRunwayAccount } from '../../graphql/mutation'

export default function AccountEdit({ accountId, open, onClose }) {
  const [account, setAccount] = useState()
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')

  const { loading, error, data, refetch } = useQuery(getRunwayAccount, {
    variables: { keyId: accountId },
    onCompleted: data => setAccount(data && data.getRunwayAccount ? data.getRunwayAccount : {}),
  })

  const handleChange = (prop) => (event) => {
    setAccount({ ...account, [prop]: event.target.value })
  }
  const [updateAccountMutation] = useMutation(updateRunwayAccount)
  const handleUpdate = async () => {
    try {
      await updateAccountMutation({
        variables: {
          keyId: accountId,
          token: account?.token,
        },
      })
      setMessage('Account updated successfully')
      setSeverity('success')
      refetch()
    } catch (error) {
      setMessage(`Account failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setAlertOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertOpen(false)
  }
  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose} style={{ width: '300px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <Box width="100%">
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '15px',
          }}
        >
          <IconButton
            onClick={onClose}
            color="secondary"
            aria-label="close modal"
            component="span"
            sx={{ alignSelf: 'flex-end' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h4" align="center" gutterBottom>
            Account Edit Form
          </Typography>

          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="id" label="ID" type="text"
                     value={account?.id || ''}
                     onChange={handleChange('id')} />
          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="createdAt" label="Created At"
                     type="text"
                     value={account?.createdAt ? new Date(account.createdAt).toLocaleString() : ''}
                     onChange={handleChange('createdAt')} />
          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="updatedAt" label="Updated At"
                     type="text"
                     value={account?.updatedAt ? new Date(account.updatedAt).toLocaleString() : ''}
                     onChange={handleChange('updatedAt')} />

          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="accountName"
                     label="Account Name" type="text"
                     value={account?.accountName || ''}
                     onChange={handleChange('productName')} />
          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="accountNo"
                     label="Account No" type="text"
                     value={account?.accountNo || ''}
                     onChange={handleChange('productType')} />
          <TextField style={{ marginBottom: '20px' }} fullWidth id="token" label="Token" type="text"
                     value={account?.token || ''}
                     onChange={handleChange('token')} />

          <TextField style={{ marginBottom: '20px' }} disabled fullWidth id="teamId" label="Team Id" type="text"
                     value={account?.teamId || ''}
                     onChange={handleChange('teamId')} />

          <Button onClick={handleUpdate} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  )
};