import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import './usertable.css'
import { useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useQuery, gql } from '@apollo/client'
import { getAllUsers } from '../../graphql/queries'
import { useState } from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'


const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt)
      return date.toLocaleDateString('en-GB')
    },
  },
  { field: 'role', headerName: 'Role', flex: 1, minWidth: 150 },
  { field: 'isPremium', headerName: 'Premium Status', flex: 1, minWidth: 150 },
  { field: 'rcUserId', headerName: 'Revenuecat Id', flex: 2, minWidth: 300 },
  {
    field: 'totalCredit',
    headerName: 'Total Credit',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => params.row.totalCredit.total,
  },
  {
    field: 'store',
    headerName: 'Store',
    flex: 1,
    minWidth: 150,
  },
]

export default function UserTable() {
  const [searchInput, setSearchInput] = useState('')
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = React.useState(1)
  const { loading, error, data } = useQuery(getAllUsers, {
    variables: {
      page: currentPage,
      pageSize: 11,
      rcUserId: searchInput,
    },
  })


  if (error) {
    return <p>Error: {error.message}</p>
  }
  const rowsData = data && data.getAllUsers && data.getAllUsers.users ? data.getAllUsers.users : []

  const filteredRows = rowsData.filter((row) =>
    row.rcUserId.toLowerCase().includes(searchInput.toLowerCase()),
  )

  const handleRowClick = (params) => {
    navigate(`/users/${params.id}`)
  }

  const handleSearchChange = (event) => {
    setCurrentPage(1)
    setSearchInput(event.target.value)
  }
  const handlePagination = (e, v) => {
    setSearchInput('')
    setCurrentPage(v)
  }

  return (
    <div style={{ width: '100%' }}>
      <Toolbar>
        <TextField
          id="filled-basic"
          name="rcUserId"
          label="RevenueCat User ID"
          variant="filled"
          value={searchInput}
          onChange={handleSearchChange}
        />
      </Toolbar>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        onRowClick={handleRowClick}
        autoHeight
        pagination
      />
      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            page={currentPage}
            onChange={(e, value) => handlePagination(e, value)}
            count={data && data.getAllUsers && Math.round(data.getAllUsers.totalCount / 11)}
            variant="outlined"
          />
        </Stack>
      </div>

    </div>
  )
}
