import React, { useState } from 'react'
import {
  Alert,
  Card,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AccountEdit from '../components/account/editAccount'
import { useQuery, useMutation } from '@apollo/client'
import { getRunwayAccounts } from '../graphql/queries'
import { Add } from '@mui/icons-material'
import CreateAccount from '../components/account/createAccount'
import { deleteRunwayAccount } from '../graphql/mutation'
import Box from '@mui/material/Box'

function TableComponent({ title, data, onDeleteSuccess, onCreateSuccess }) {
  const [openModal, setOpenModal] = React.useState(false)
  const [openModal2, setOpenModal2] = React.useState(false)
  const [selectedRowId, setSelectedRowId] = React.useState(null)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const [open, setOpen] = useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)

  const [accountDeleteMutation] = useMutation(deleteRunwayAccount)

  const handleOpenModal = (row) => {
    setSelectedRowId(row.id)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenModal2 = () => {
    setOpenModal2(true)
  }

  const handleCloseModal2 = () => {
    setOpenModal2(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 15))
    setPage(0)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleAccountDelete = async (row) => {
    try {
      await accountDeleteMutation({
        variables: {
          accountId: row.id,
        },
      })

      // Call the callback to update parent state
      onDeleteSuccess(row.id)

      setMessage('Account deleted successfully')
      setSeverity('success')
    } catch (error) {
      setMessage(`Account delete failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setOpen(true)
    }
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '300px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <Box width="100%">
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <Card style={{ flex: '0 1 calc(50% - 10px)', margin: '5px' }}>
        <TableContainer style={{ flex: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell colSpan={data.length + 2}>
                  <Typography
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {title}
                    <IconButton color="primary" aria-label="create">
                      <Add onClick={handleOpenModal2} />
                    </IconButton>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>accountName</TableCell>
                <TableCell>isActive</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || [])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((a, b) => b.health.isActive - a.health.isActive)
                .reverse()
                .map((row, i) => (
                  <TableRow key={row.id || i}>
                    <TableCell style={{ height: '83px' }}>
                      {row.accountName}
                    </TableCell>
                    <TableCell>
                      {row.health.isActive ? (
                        <span style={{ color: 'green' }}>● Active</span>
                      ) : (
                        <div>
                          <span style={{ color: 'red' }}>● Inactive</span>
                          {row.health.error && (
                            <div style={{ color: 'red', fontSize: '0.875rem' }}>
                              {row.health.error}
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(row)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton color="error" onClick={() => handleAccountDelete(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TablePagination
              sx={{ padding: '8px' }}
              component="div"
              count={data?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
        <AccountEdit
          open={openModal}
          onClose={handleCloseModal}
          accountId={selectedRowId}
        />
        <CreateAccount
          open={openModal2}
          onClose={handleCloseModal2}
          createSuccess={onCreateSuccess}
        />
      </Card>
    </>
  )
}

export default function AccountPage() {
  const [runwayAccounts, setRunwayAccounts] = React.useState([])

  const { loading, error, data, refetch } = useQuery(getRunwayAccounts, {
    onCompleted: (data) => {
      setRunwayAccounts(data.getRunwayAccounts)
    },
  })

  const handleDeleteSuccess = (deletedId) => {
    // Update the local state by filtering out the deleted account
    setRunwayAccounts(prevAccounts =>
      prevAccounts.filter(account => account.id !== deletedId),
    )
  }

  const handleCreateSuccess = async () => {
    await refetch()
    const latestData = await data
    setRunwayAccounts(latestData.getRunwayAccounts)
  }

  if (error) {
    console.error('Error fetching data: ', error)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <TableComponent
        title={'Runway Accounts'}
        data={runwayAccounts}
        onDeleteSuccess={handleDeleteSuccess}
        onCreateSuccess={handleCreateSuccess}
      />
      <TableComponent
        title={'HailuoAI Accounts'}
        data={runwayAccounts}
        onDeleteSuccess={handleDeleteSuccess}
        onCreateSuccess={handleCreateSuccess}
      />
    </div>
  )
}