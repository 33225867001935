const AWS = require('aws-sdk')
const { v4: uuidv4 } = require('uuid')

// Configure AWS credentials and region
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'eu-central-1',
})

// Create S3 instance
const s3 = new AWS.S3()

export const uploadToS3 = (file, type) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: 'videoai-uploads',
      Key: type === 'img' ? `${uuidv4()}.jpeg`
        : type === 'gif' ? `${uuidv4()}.gif`
          : type === 'webm' ? `${uuidv4()}.webp`
            : `${uuidv4()}.mp4`,
      Body: file,
    }

    const options = { partSize: 5 * 1024 * 1024, queueSize: 10 }

    s3.upload(params, options, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data.Location) // Resolve with the URL of the uploaded image
      }
    })
  })
}
