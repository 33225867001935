import * as React from 'react'
import { Card, CardContent, CardHeader, Toolbar, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { useState } from 'react'

const columns = [
  { field: 'prompt', flex: 1, headerName: 'Prompt', minWidth: 100 },
  { field: 'inputImg', flex: 1, headerName: 'Input Image', minWidth: 100 },
  { field: 'type', flex: 1, headerName: 'Type', minWidth: 100 },
  { field: 'category', flex: 1, headerName: 'Category', minWidth: 100 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 50,
    flex: 1,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt)
      return date.toLocaleDateString('tr-TR')
    },
  },
]

export default function ModerationTable({ data }) {
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>Moderations</Typography>
      <DataGrid
        rowHeight={100}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
      />
    </div>
  )
}