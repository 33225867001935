import React from 'react'
import { Box, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'

const VideoAnalytic = ({videoData}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6" id="tableTitle" component="div">
                User Expenditures
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <Box display="flex" flexDirection="column" flexWrap="wrap" p={1}>
          <Box display="flex" flexDirection="column" alignItems="stretch" width="100%" p={1}>
            <Box display="flex" justifyContent="space-between" width="100%" gap={5} p={1}>
              <TextField
                disabled
                fullWidth
                inputProps={{
                  'aria-label': 'Total Video',
                }}
                value={videoData?.total ?? ''}
                label="Total Video"
              />
              <TextField
                disabled
                fullWidth
                inputProps={{
                  'aria-label': 'Succeeded Video',
                }}
                value={videoData?.succeeded ?? ''}
                label="Succeeded Video"
              />
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%" gap={5} p={1}>
              <TextField
                disabled
                fullWidth
                inputProps={{
                  'aria-label': 'Failed Video',
                }}
                value={videoData?.failed ?? ''}
                label="Failed Video"
              />
              <TextField
                disabled
                fullWidth
                inputProps={{
                  'aria-label': 'Credit Refund Amount',
                }}
                value={videoData?.refund ?? ''}
                label="Credit Refund Amount"
              />
            </Box>
          </Box>
        </Box>
      </Table>
    </TableContainer>
  )
}

export default VideoAnalytic