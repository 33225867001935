import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export default function NavBar() {
  const navigate = useNavigate();

  const handleLogOut = () => {
    window.localStorage.removeItem('token');
    navigate('/login');
    window.location.reload();
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#333', padding: '0 20px' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            videoai-betaaa
          </Link>
        </Typography>
        <IconButton
          onClick={handleLogOut}
          color="inherit"
          aria-label="logout"
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
