import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useQuery, gql } from '@apollo/client'
import { getCategories, getExplorePage } from '../../graphql/queries'
import ProgressBar from '../circularProgress/circularProgress'


const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
  {
    field: 'thumbnail',
    headerName: 'Thumbnail',
    flex: 1,
    renderCell: (params) => (
      <img src={params.value} alt="Thumbnail" style={{ width: '100%', height: '250px' }} />
    ),
  },

]

export default function CategoryTable() {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(getCategories)
  if (loading) {
    return <ProgressBar />
  }

  const handleRowClick = (params) => {
    navigate(`/categories/${params.id}`)
  }

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const rowsData = data && data.getCategories ? data.getCategories : []
  return (
    <div style={{ width: '100%' }}>
      <Toolbar>
        <div className="d-flex flex-row-reverse w-100">
          <Link to="/categories/create">
            <button className="btn btn-sm btn-dark me-3">Create</button>
          </Link>
        </div>
      </Toolbar>
      <DataGrid
        rowHeight={200}
        rows={rowsData}
        columns={columns}
        checkboxSelection
        onRowClick={handleRowClick}
      />
    </div>
  )
}
