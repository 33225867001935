import * as React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Card, CardContent, CardHeader } from '@mui/material'
import Stack from '@mui/material/Stack'


const COLORS = ['#a3b9e2', '#b2d8b4', '#f2b2b2', '#c8c8c8', '#d0e2f7', '#e3e3e3']

export default function CustomPieChart({ chartData, sx }) {
  const data = [
    { name: 'Pending', value: chartData && chartData.pending },
    { name: 'Succeeded', value: chartData && chartData.succeeded },
    { name: 'Failed', value: chartData && chartData.failed },
    { name: 'Running', value: chartData && chartData.running },
    { name: 'In Queue', value: chartData && chartData.in_queue },
    { name: 'Throttled', value: chartData && chartData.throttled },
  ]
  return (
    <Card sx={{ ...sx, paddingBottom: 4 }}>
      <CardHeader title="Today Task Status Overview" />
      <CardContent>
        <Stack alignItems="center">
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Stack>
      </CardContent>
    </Card>
  )
}
