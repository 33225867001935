import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { uploadToS3 } from '../../utils/uploadToS3'
import Alert from '@mui/material/Alert'
import { useMutation, useQuery } from '@apollo/client'
import { getAllStyles, getCategories } from '../../graphql/queries'
import ProgressBar from '../../components/circularProgress/circularProgress'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPlayer from 'react-player'
import { createExplore } from '../../graphql/mutation'


const selectMotionScoreData = [
  { value: 1, name: 'ONE' },
  { value: 2, name: 'TWO' },
  { value: 3, name: 'THREE' },
  { value: 4, name: 'FOUR' },
  { value: 5, name: 'FIVE' },
  { value: 6, name: 'SIX' },
  { value: 7, name: 'SEVEN' },
  { value: 8, name: 'EIGHT' },
  { value: 9, name: 'NINE' },
  { value: 10, name: 'TEN' },
]
const selectAspectRatioData = [
  { value: 'WIDESCREEN', name: 'WIDESCREEN' },
  { value: 'PORTRAIT', name: 'PORTRAIT' },
  { value: 'SQUARE', name: 'SQUARE' },
  { value: 'FULL_SCREEN', name: 'FULL SCREEN' },
  { value: 'TABLET', name: 'TABLET' },
  { value: 'ULTRAWIDE', name: 'ULTRAWIDE' },
]

export default function CreateExplorePage() {
  const [file, setFile] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState()
  const [video, setVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [webm, setWebm] = useState(null)
  const [selectedWebm, setSelectedWebm] = useState(null)
  const [selectedVideoUrl, setSelectedVideoUrl] = useState()
  const [show, setShow] = React.useState('none')
  const [categories, setCategories] = useState([])
  const [progress, setProgress] = useState(false) // State for progress bar
  const [explore, setExplore] = useState({
    prompt: null,
    url: null,
    thumbnailUrl: null,
    motionScore: null,
    style: null,
    aspectRatio: null,
    categoryId: null,
    webmUrl: null,
  })
  const { loading, error, data } = useQuery(getAllStyles)
  const { loading: categoryLoading, error: categoryError, data: categoryData } = useQuery(getCategories)


  useEffect(() => {
    if (!categoryLoading && categoryData) {
      setCategories(categoryData.getCategories)
    }
  }, [categoryLoading, categoryData])

  const selectStyleData = data && data.getAllStyles ? data.getAllStyles : []
  const handleChange = (e) => {
    const { name, value } = e.target
    setExplore((prev) => ({ ...prev, [name]: value }))
  }

  const handleBannerFileSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedFileUrl(URL.createObjectURL(selectedFile))
    setFile(selectedFile)
  }
  const handleVideoSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedVideoUrl(URL.createObjectURL(selectedFile))
    setVideo(selectedFile)
  }
  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))
    setGif(selectedFile)
  }
  const handleWebm = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedWebm(URL.createObjectURL(selectedFile))
    console.log(URL.createObjectURL(selectedFile))
    setWebm(selectedFile)
  }
  const handleVideoUrl = (e) => {
    const videoUrl = e.target.value
    setSelectedVideoUrl(videoUrl)

  }
  const handleGifUrl = (e) => {
    const videoUrl = e.target.value
    setSelectedGifUrl(videoUrl)

  }


  const [createExploreMutation] = useMutation(createExplore)
  const handleCreateExplore = async () => {
    try {
      // Start loading state
      setProgress(true)

      // Parallel uploads for better performance
      const [s3VideoUrl, s3ThumbnailUrl, s3GifUrl, webmmUrl] = await Promise.all([
        video ? uploadToS3(video) : selectedVideoUrl,
        file ? uploadToS3(file, 'img') : selectedFileUrl,
        gif ? uploadToS3(gif, 'gif') : selectedGifUrl,
        webm ? uploadToS3(webm, 'webm') : selectedWebm,
      ])

      // Validate required fields before mutation
      if (!explore.prompt || !s3VideoUrl) {
        throw new Error('Prompt and video are required')
      }

      // Create mutation with clear, type-safe variables
      const gpqResponse = await createExploreMutation({
        variables: {
          prompt: explore.prompt,
          url: s3VideoUrl,
          thumbnailUrl: s3ThumbnailUrl,
          motionScore: explore.motionScore?.toLowerCase() ?? null,
          style: explore.style,
          gif: s3GifUrl,
          webmUrl: webmmUrl,
          aspectRatio: explore.aspectRatio,
          categoryId: explore.categoryId,
        },
      })

      // Handle successful creation
      if (gpqResponse.data) {
        setShow('block')

        // Use cleanup function to handle timeout
        const timeoutId = setTimeout(() => {
          setShow('none')
        }, 3000)

        // Optional: Return cleanup function for component unmount
        return () => clearTimeout(timeoutId)
      }
    } catch (error) {
      // Centralized error handling
      console.error('Error creating explore:', error)

      // Optional: show error to user
      // toast.error(error.message);

      // Ensure progress is set to false even on error
      setProgress(false)
    } finally {
      // Ensure progress is always set to false
      setProgress(false)
    }
  }

  return (
    <div>
      <div className="fixed-top" style={{ display: show }}>
        <Alert severity="success" color="info">
          Explore successfully created.
        </Alert>
      </div>
      <div className="d-flex flex-column">
        <TextField
          fullWidth
          id="filled-basic"
          label="Prompt"
          variant="filled"
          name="prompt"
          onChange={handleChange}
        />
        <div className="w-25 mb-3">
          <span>Category</span>
          <FormControl fullWidth>
            <Select
              name="categoryId"
              onChange={handleChange}
            >
              {
                categories.map(i => (
                  <MenuItem value={i.id}>{i.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Video Url"
            variant="filled"
            name="videoUrl"
            onChange={handleVideoUrl}
          />
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Thumbnail Url"
            variant="filled"
            name="thumbnailUrl"
            onChange={handleGifUrl}
          />
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Gif Url"
            variant="filled"
            name="gifUrl"
            onChange={handleGifUrl}
          />
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Webm Url"
            variant="filled"
            name="webmUrl"
            onChange={handleGifUrl}
          />
        </div>
        {
          progress && <ProgressBar />
        }
        <span>Thumbnail Photo</span>
        <div className="mb-3 d-flex flex-column  pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleBannerFileSelected}
          />
          {
            selectedFileUrl && <img style={{ width: '25%' }} src={selectedFileUrl} />
          }
        </div>
        <span>Video</span>
        <div className="mb-3 d-flex flex-column  pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleVideoSelected}
          />
          {
            selectedVideoUrl &&
            <div className="d-flex justify-content-start mt-3">
              <div>
                <ReactPlayer url={selectedVideoUrl} controls playing />
              </div>
            </div>
          }

        </div>
        <span>Gif</span>
        <div className="mb-3 d-flex flex-column  pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleGifSelected}
          />
          {
            selectedGifUrl && <img style={{ width: '25%' }} src={selectedGifUrl} />
          }

        </div>
        <span>Webm View</span>
        <div className="mb-3 d-flex flex-column  pb-2 pt-2">
          <input
            name="webmFile"
            type="file"
            onChange={handleWebm}
          />
          {
            selectedWebm && <img style={{ width: '25%' }} src={selectedWebm} controls playing />
          }

        </div>
        <div className="d-flex justify-content-start">
          <button
            onClick={handleCreateExplore}
            className="btn btn-dark fs-6"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  )
}
