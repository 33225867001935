import * as React from 'react'
import UserTable from './components/userTable/usertable'
import SideMenu from './components/sidemenu/sidemenu'
import CategoryTable from './components/categoryTable/categoryTable'
import SingleUserPage from './pages/singleuserPage'
import VideoTable from './components/videoTable/videoTable'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import LoginPage from './pages/loginPage'
import NavBar from './components/navbar/navBar'
import HomePage from './pages/homePage'
import SingleVideoPage from './pages/singlevideoPage'
import ExploreTable from './components/exploreTable/exploretable'
import SingleExplorePage from './pages/singleExplorePage'
import CreateExplorePage from './pages/createPages/createExplore'
import CreateCategoryPage from './pages/createPages/createCategory'
import SingleCategoryPage from './pages/categoryPage'
import CreateCreditPage from './pages/createPages/createCredit'
import SystemPage from './pages/systemPage'
import FeedTable from './components/feedTable/feedtable'
import Support from './pages/supportPage'
import AccountPage from './pages/accountPage'

export default function App() {
  const isLoggedIn = window.localStorage.getItem('token')
  return (
    <Router>
      {isLoggedIn ? (
        <div>
          <div>
            <NavBar />
          </div>
          <div className="d-flex">
            <div className="col-1">
              <SideMenu />
            </div>
            <div className="col-11">
              <Routes>
                <Route path="/feed" element={<FeedTable />} />
                <Route path="/users" element={<UserTable />} />
                <Route path="/styles" element={<ExploreTable />} />
                <Route path="/system" element={<SystemPage />} />
                <Route path="/accounts" element={<AccountPage />} />
                <Route path="/categories" element={<CategoryTable />} />
                <Route path="/categories/:id" element={<SingleCategoryPage />} />
                <Route path="/categories/create" element={<CreateCategoryPage />} />
                <Route path="/styles/create" element={<CreateExplorePage />} />
                <Route path="/styles/:id" element={<SingleExplorePage />} />
                <Route path="/users/:id" element={<SingleUserPage />} />
                <Route path="/videos" element={<VideoTable />} />
                <Route path="/support" element={<Support />} />
                <Route path="/videos/:id" element={<SingleVideoPage />} />
                <Route path="/credits/create" element={<CreateCreditPage />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  )
}
