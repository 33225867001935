import * as React from 'react'
import Widget from '../components/widget/widget'
import Chart from '../components/chart/chart'
import { useQuery } from '@apollo/client'
import { dashboardData } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'
import CustomPieChart from '../components/pieChart/pieChart'
import Grid from '@mui/material/Unstable_Grid2'

export default function HomePage() {
  const { loading, data } = useQuery(dashboardData)
  const rowData = data && data.dashboardData ? data.dashboardData : []

  if (loading) {
    return <ProgressBar />
  }

  const dailyVideo = rowData && rowData.dailyVideoCount
  const reversedArray = dailyVideo ? dailyVideo.slice().reverse() : []
  return (
    <Grid container spacing={3}>
      <Grid lg={3} sm={6} xs={12}>
        <Widget type="users"
                amount={rowData.totalUser}
        />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <Widget type="models"
                amount={rowData.activeSubscriptions}
        />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <Widget type="predictions"
                amount={rowData.totalVideo}
        />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <Widget type="luma"
                amount={rowData.lumaCreditValue}
        />
      </Grid>
      <Grid lg={8} xs={12}>
        <Chart
          title="Weekly Prediction Count"
          aspect={2 / 1}
          predictionData={reversedArray}
        />
      </Grid>
      <Grid lg={4} md={6} xs={12}>
        <CustomPieChart chartData={rowData.pieChartData} sx={{ height: '100%' }} />
      </Grid>
    </Grid>

  )
}
