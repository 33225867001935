import React, { useState } from 'react'
import { Modal, Alert, TextField, Box, IconButton, Grid, Typography, Button, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from '@apollo/client'
import { createRunwayAccount } from '../../graphql/mutation'

export default function CreateAccount({ open, onClose, createSuccess }) {
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')
  const [account, setAccount] = useState({
    accountName: '',
    token: '',
    teamId: '',
  })


  const handleChange = (prop) => (event) => {
    setAccount({ ...account, [prop]: event.target.value })
  }
  const [createRunwayAccountMutation] = useMutation(createRunwayAccount)
  const handleUpdate = async () => {
    try {
      await createRunwayAccountMutation({
        variables: {
          accountName: account.accountName,
          token: account.token,
          teamId: Number(account.teamId),
        },
      })
      setMessage('Account created successfully')
      setSeverity('success')
      createSuccess()
    } catch (error) {
      setMessage(`Account failed: ${error.message}`)
      setSeverity('error')
    } finally {
      setAlertOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertOpen(false)
  }
  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose} style={{ width: '300px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <Box width="100%">
            {message}
          </Box>
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Reduced width
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '15px',
          }}
        >
          <IconButton
            onClick={onClose}
            color="secondary"
            aria-label="close modal"
            component="span"
            sx={{ alignSelf: 'flex-end' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h4" align="center" gutterBottom>
            Account Create Form
          </Typography>

          <TextField style={{ marginBottom: '8px' }} mb={1} fullWidth id="accountName" name="accountName"
                     label="Account Name" type="text"
                     value={account?.accountName || ''}
                     onChange={handleChange('accountName')} />
          <TextField style={{ marginBottom: '8px' }} mb={1} fullWidth id="token" name="token"
                     label="Token" type="text"
                     value={account?.token || ''}
                     onChange={handleChange('token')} />
          <TextField style={{ marginBottom: '8px' }} mb={1} fullWidth id="teamId" name="teamId"
                     label="teamId" type="number"
                     value={account?.teamId || ''}
                     onChange={handleChange('teamId')} />
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Create
          </Button>
        </Box>
      </Modal>
    </div>
  )
};