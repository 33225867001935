import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs library

export default function BasicDatePicker({onChange}) {


  const handleDateChange = (date) => {
    onChange(dayjs(date).format('MM/DD/YYYY'))
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label="Basic date picker"
          onChange={handleDateChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
